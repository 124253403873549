import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function TrusteeAreasPage() {
  return (
    <Layout>
      <Seo title="Thanks for donating" />
      <div style={{ textAlign: "center" }}>
        <h1>Thank you for donating</h1>
        <StaticImage
          src="../images/thank-cat.jpg"
          alt={"cat sleeping in a box that says Thank you"}
        />
      </div>
    </Layout>
  )
}

export default TrusteeAreasPage
